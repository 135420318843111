@import url("https://fonts.googleapis.com/css?family=Material+Symbols+Outlined|Lato:400,700&display=swap");

:root {
  --color-primary: #3297b3;
  --color-primary-dark: #1d5766;
  --color-primary-darker: #032533;
  --color-primary-light: #4fb3cf;
  --color-primary-lighter: #f2fbfb;
  --color-secondary: #a400fb;
  --color-tertiary: #e7642b;
  --color-tertiary-dark: #d15a27;
  --color-error: #cf1d11;
  --color-error-dark: #88171b;
  --color-warning: #ffcc00;
  --color-warning-dark: #b5a436;
  --color-disabled: #eee;

  --surface: #fff;
  --surface-active: #eee;
  --surface-inverted: #042838;
  --surface-primary: #f2fbfb;
  --surface-tertiary: #f2f0e1;
  --surface-tertiary-dark: var(--color-tertiary);
  --surface-backdrop: rgba(0, 0, 0, 0.5);

  --border: var(--border-width) solid var(--border-color);
  --border-input: var(--border-width) solid var(--border-color-input);
  --border-color: #e0e0e0;
  --border-color-input: #e0e0e0;
  --border-color-error: var(--color-error);
  --border-width: 1px;
  --border-radius: 5px;

  --font-color-base: #333;
  --font-color-light: #666;
  --font-color-lighter: #999;
  --font-color-lightest: #fff;
  --font-color-primary: var(--color-primary);
  --font-color-primary-dark: var(--color-primary-dark);
  --font-color-error: var(--color-error);
  --font-color-tertiary: var(--color-tertiary);

  --font-family: "Lato", sans-serif;
  --font-size-xs: 0.75rem;
  --font-size-s: 0.875rem;
  --font-size-m: 16px;
  --font-size-l: 1.125rem;
  --font-size-xl: 1.5rem;
  --font-size-xxl: 2rem;
  --font-size-xxxl: 3rem;
  --font-size-hero: 4rem;

  --spacing-xxxs: 0.25rem;
  --spacing-xxs: 0.5rem;
  --spacing-xs: 0.75rem;
  --spacing-s: 1rem;
  --spacing-m: 1.25rem;
  --spacing-l: 1.5rem;
  --spacing-xl: 2rem;
  --spacing-xxl: 4rem;

  --z-default: 1;
  --z-cta: 999;
  --z-backdrop: 1000;

  --box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  --box-shadow-hover: 2px 4px 4px rgba(0, 0, 0, 0.2);

  --landing-max-width: 1024px;
  --page-max-width: 1920px;
  --modal-max-height: 90vh;

  /* Use these VALUES in media queries until vars are supported */
  --breakpoint-max-mobile: 768px;
  --breakpoint-max-tablet: 1024px;
}

body {
  margin: 0;
  background-color: var(--color-primary-lighter);
  color: var(--font-color-base);
  font-size: var(--font-size-m);
  font-family: var(--font-family);
}
